import { Observable } from 'rxjs';

/**
 * @description Intefaz usada para soportar la internacionalización de la aplicación
 */
export abstract class AppTranslationGateway {
  /**
   * Método para obtener una traducción
   * @param key clave de la traducción
   * @param params parámetros extras de la traducción
   * @returns traducción
   * */
  abstract getAsync(
    key: string,
    params?: Record<string, string>
  ): Observable<string>;

  /**
   * Método para obtener una traducción de forma sincrona
   * @param key clave de la traducción
   * @param params parámetros extras de la traducción
   * @returns traducción
   * */
  abstract get(key: string, params?: Record<string, string>): string;
}
