/* eslint-disable @typescript-eslint/no-unsafe-return */
import { inject, Injectable } from '@angular/core';
import { _, TranslateService } from '@ngx-translate/core';
import { AppTranslationGateway } from '@service/translation/app-translation.gateway';
import { map, Observable } from 'rxjs';

/**
 * Servicio de traducción que usa ngx-translate.
 */
@Injectable({
  providedIn: 'root'
})
export class NgxTranslatorService extends AppTranslationGateway {
  /**
   * Servicio de traducción de ngx-translate.
   */
  private ngxTranslator = inject(TranslateService);

  /**
   * Obtiene una traducción de forma asíncrona.
   * @param key clave de traducción
   * @param params  parametros para hacer interpolación en la traducción.
   * @returns {Observable<string>} Cadena de texto traducida.
   */
  getAsync(key: string, params?: Record<string, string>): Observable<string> {
    return this.ngxTranslator
      .get(_(`app.${key}`), params)
      .pipe(map((translation: string) => translation));
  }

  /**
   * Obtiene una traducción de forma síncrona.
   * @param key  clave de traducción
   * @param params  parametros para hacer interpolación en la traducción.
   * @returns  {string} Cadena de texto traducida.
   */
  get(key: string, params?: Record<string, string>): string {
    return this.ngxTranslator.instant(_(`app.${key}`), params);
  }
}
